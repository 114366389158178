import React from 'react'

import ContactForm from './ContactForm'
import Reviews from './Reviews'
import Menu from './Menu'







export default function ContactPage() {
    return (
        <div style={{ width: "100vw", backgroundColor: '#EDE7D9', }}>
            <Menu />

            <div className='contact-banner'>
                <div className="banner-content">
                    <h2 style={{ fontSize: '24px' }}>"Hotel Rooms Are Soon Forgotten, Memories Last A Lifetime"</h2>
                    <h2 className='white' style={{ fontSize: '64px' }}>CONTACT</h2>
                    <p style={{ textAlign: 'center', color: '#EFF1ED' }}>We are here to answer any questions you might have and make sure your stay at Schweitzer Castle is an unforgetable experince</p>
                    <a href="https://www.vacasa.com/unit/59180"><button className='button-contact' >Book Now</button></a>
                </div>
            </div>
            <div className='form-container'>

                <ContactForm />

                <Reviews />

            </div>
        </div>
    )
}
