import React, { useState, useEffect } from 'react'
import Axios from 'axios'
import { Rate, Icon } from 'antd';

export default function Reviews() {

    const [revArr, setrevArr] = useState([])
    useEffect(() => {
        Axios.get('https://tranquil-peak-77628.herokuapp.com/reviews').then(res => {
            const { data: { result } } = res
            setrevArr(result.reviews)
        }).catch(err => console.log(err))

    }, [])

    return (
        <div style={{ marginBottom: '30px' }} className="contact-item"  >

            <h2 style={{ fontFamily: "'IM Fell English SC', serif", fontSize: '32px', color: '#0D1B1E' }}>What people are saying about us</h2>
            <hr style={{ border: '1px solid #0F7173', marginBottom: '20px' }} />

            <div style={{ height: '550px', overflow: 'auto' }}>

                {revArr.map(review => {
                    return (
                        <div key={review.time}>
                            <Rate disabled defaultValue={review.rating} />
                            <p style={{ overflow: 'auto', fontFamily: "'Josefin Sans', sans-serif", color: '#0D1B1E' }} >{review.text}</p>
                            <a href={review.author_url}><h3 style={{ fontFamily: "'IM Fell English SC', serif", color: '#0D1B1E' }}> - {review.author_name}</h3></a>
                            <img style={{ width: '100px' }} src="https://upload.wikimedia.org/wikipedia/commons/thumb/2/2f/Google_2015_logo.svg/1280px-Google_2015_logo.svg.png" alt="google_logo" />
                        </div>)
                })}
            </div>
            <h2 style={{ fontFamily: "'IM Fell English SC', serif", fontSize: '32px', color: '#0D1B1E' }}>leave a review:</h2>
            <div style={{ width: '100%', display: 'flex', justifyContent: 'space-evenly', alignItems: 'center' }}>
                <a href="https://www.yelp.com/biz/chateau-de-melusine-sandpoint"><img style={{ width: '100px' }} src="https://upload.wikimedia.org/wikipedia/commons/thumb/a/ad/Yelp_Logo.svg/320px-Yelp_Logo.svg.png" alt="yelp_logo" /></a>
                <a href="https://www.google.com/maps/place/Schweitzer+Castle/@48.3606755,-116.6248492,17z/data=!3m1!4b1!4m7!3m6!1s0x0:0xbc17e8eae4d98ef4!8m2!3d48.3606755!4d-116.6226605!9m1!1b1"><img style={{ width: '100px' }} src="https://upload.wikimedia.org/wikipedia/commons/thumb/2/2f/Google_2015_logo.svg/1280px-Google_2015_logo.svg.png" alt="google_logo" /></a>

            </div>
        </div>



    )
}
require('dotenv').config()
