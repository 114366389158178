import React from 'react';
import { Link } from "react-router-dom";
import { Zoom } from 'react-reveal'
import './App.css';
import { Icon } from 'antd'


import PhotoCarousel from './components/PhotoCarousel';
import Menu from './components/Menu';
import IconGrid from './components/IconGrid';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faToilet } from '@fortawesome/free-solid-svg-icons';





function App() {

  const cmArr = [
    'https://res.cloudinary.com/neversettels/image/upload/v1569280022/SchweitzerPics/melusine2_vipbak.jpg',
    'https://res.cloudinary.com/neversettels/image/upload/v1568916850/SchweitzerPics/Living_Room_jpmarw.jpg',
    'https://res.cloudinary.com/neversettels/image/upload/v1569276797/SchweitzerPics/fireplace_mdsoo4.jpg',
    'https://res.cloudinary.com/neversettels/image/upload/v1569280082/SchweitzerPics/melusine3_view_ayx8kz.jpg',
    'https://res.cloudinary.com/neversettels/image/upload/v1569276712/SchweitzerPics/bedroom_ihtcdi.jpg',
    'https://res.cloudinary.com/neversettels/image/upload/v1569276766/SchweitzerPics/hottub_jnuiq9.jpg'
  ]

  const eArr = [

    'https://res.cloudinary.com/neversettels/image/upload/w_1000,ar_16:9,c_fill,g_auto,e_sharpen/v1565640079/SchweitzerPics/image6_ockezv.jpg',
    'https://res.cloudinary.com/neversettels/image/upload/w_1000,ar_16:9,c_fill,g_auto,e_sharpen/v1565640121/SchweitzerPics/IMG_4049_mdvg7i.jpg',
    'https://res.cloudinary.com/neversettels/image/upload/w_1000,ar_16:9,c_fill,g_auto,e_sharpen/v1565640090/SchweitzerPics/image1_ypj70q.jpg',
    'https://res.cloudinary.com/neversettels/image/upload/w_1000,ar_16:9,c_fill,g_auto,e_sharpen/v1565640123/SchweitzerPics/IMG_4044_yfdp9l.jpg',
    'https://res.cloudinary.com/neversettels/image/upload/w_1000,ar_16:9,c_fill,g_auto,e_sharpen/v1569288898/SchweitzerPics/kissing_melusine_nvdi9d.jpg',


  ]

  return (
    <div className="App">

      <Menu />


      <div className='header'>
        <Zoom>
          <div className="logo">
            <img src="https://res.cloudinary.com/neversettels/image/upload/v1568920310/SchweitzerPics/Chateau_de_Melusine_logo_pic_s5e8rq.png" alt="Chateau-de-Melusine-logo" />

          </div>
        </Zoom>
        <h2 style={{ fontSize: '24px', marginTop: '200px' }}>"Hotel Rooms Are Soon Forgotten, Memories Last A Lifetime"</h2>
      </div>

      <div className="about">
        <div className='width'>
          <h1>Schweitzer Castle   <strong>&</strong>  <br /> Château de Melusine</h1>
          <hr className="green-bar" />
          <p >
            Located less than 5 minutes away from Schweitzer mountain resort, Schweitzer Castle’s Château de Melusine is the best place for you and your family to stay and enjoy a unique experience.
             We  are a close distance away from Schweitzer mountain resort which allows you all the amenities of staying at the hotel
          while remaining independent in the best place to stay in Northern Idaho. <strong> Whether its summer time lake adventures or winter time fun on the slopes don’t miss out on the best stay of your life! </strong>
          </p>
        </div>
        <div>
          <img style={{ marginRight: '15px', marginBottom: '15px' }} className='about-img' src='https://res.cloudinary.com/neversettels/image/upload/v1577384602/SchweitzerPics/IMG_1818_s2cokb.jpg' alt='Schweitzer Castle' ></img>
          <img style={{ marginBottom: '15px' }} className='about-img' src="https://res.cloudinary.com/neversettels/image/upload/v1569273741/SchweitzerPics/melusine_z26166.jpg" alt="Château de Melusine" />
        </div>
      </div>

      <div className="info">
        <h2 className="white">What to expect</h2>
        <div className="info-container">

          <PhotoCarousel cmArr={cmArr} />
          <div className="info-text" >
            <div className='width'>
              <h2 className='white'>The Château</h2>
              <hr className='white-bar' />
              <p className='white'>You can have the opportunity to be a King and Queen with a stay in Château de Melusine.
                With the Chateau's construction of 22" thick quarried stone, a 50" fireplace in the great room,
                 and hydronic-heat floors of Idaho Quartzite you'll experience the magical feeling of vacationing like royaly.
            </p>
              <div className="icon-box">
                <span> <Icon type="appstore" /> 1,300 sq ft</span>
                <span> <Icon type="team" /> Sleeps 9</span>
                <span> <Icon type="home" /> 3 bedrooms</span>
                <span> <FontAwesomeIcon icon={faToilet} /> 1.5 bathrooms</span>
                <span> <Icon type="wifi" /> Free Wifi</span>
                <span><Icon type="desktop" /> Cable TV</span>
                <span> <Icon type="car" /> Free Parking</span>
                <span> <Icon type="thunderbolt" />Car Charging Port</span>
                <span> <Icon type="skin" /> Laundry Room</span>

              </div>
            </div>
          </div>
        </div>

        <hr style={{ margin: '5vh', width: '60vw', border: '1px solid #0F7173' }} />

        <div className="info-container">


          <div className="info-text" >
            <div className='width'>
              <h2 className="white">Fun for Any event</h2>
              <hr className='white-bar' />
              <p className='white'>
                Chateau de Melusine is more than just a place to stay, come live the adventures you've always wanted in a castle straight out of a fairy tale. Discover the treasure trove of
                antiques from the 15th and 16th centuries that adorn the castle and chateau and be transported to another time.
                 From Cosplayers to newly weds or family gatherings there is unique and unforgetable experience to be had in this magical place.
                 <strong> What are you waiting for? Contact us and discover the right adventure for you! </strong>
              </p>
              <Link exact='true' to="/contact"><button className='button-contact'>Contact us!</button></Link>

            </div>

          </div>
          <PhotoCarousel cmArr={eArr} />
        </div>



      </div>

      <div className='about'>
        <div className='info-text'>
          <div className='width'>
            <h2 > Things to do nearby</h2>
            <hr className='green-bar' />
            <p>
              Sandpoint and its sorrounding area is full of fun activities. Don't miss a chance to stay with us and discover its many wonders. Everyone from the most
              active adventurer to the relaxed lounger can find just the right thing! Ski the slopes of Schweitzer Mountain Resort or enjoy the many activities of Lake Pend Oreille in Sandpoint Id, there is
              always something to do no matter the time of the year! <strong> Click on the Icons to discover some of the things Northern Idaho has to offer! </strong></p>
          </div>
        </div>


        <IconGrid />

      </div>


    </div >
  );
}

export default App;