import React, { useState } from 'react'
import Hamburger from './Hamburger'
import MenuItems from './MenuItems'


export default function Menu() {
    const [open, setopen] = useState(false)
    const [style, setstyle] = useState(['', '', '', ' '])


    const transition = () => {

        if (open) {
            setopen(false)
            setstyle(['', '', '', ' '])
        } else {
            setopen(true)
            setstyle(['lineTop', 'lineMiddle', 'lineBottom', '-open'])
        }
    }


    return (
        <div className={`menu${style[3]} `}>
            <Hamburger style={style} transition={transition} />
            {open ? <MenuItems /> : ''}

        </div>
    )
}
