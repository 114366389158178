import React, { useEffect, useState } from 'react'
import { Carousel } from 'antd';

export default function PhotoCarousel(props) {
    const [photos, setphotos] = useState([])
    useEffect(() => {
        setphotos(props.cmArr)

        // eslint-disable-next-line    
    }, [])
    return (
        <Carousel className='carousel-width' autoplay>

            {photos.map((url, i) => {
                return (
                    <img key={i} src={url} alt="''" />
                )
            }
            )}
        </Carousel>
    )
}
