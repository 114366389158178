import React from 'react'

export default function Hamburger({ style, transition }) {

    return (
        <div onClick={transition} className='ham-container'>
            <div className={`line ${style[0]}`}></div>
            <div className={`line ${style[1]}`}></div>
            <div className={`line ${style[2]}`}></div>
        </div>
    )
}
