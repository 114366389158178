import React from 'react';
import { Formik, Form, Field } from 'formik';
import * as Yup from 'yup';
import Axios from 'axios';

import { Typography, notification } from 'antd';
const { Text } = Typography;

const SignupSchema = Yup.object().shape({
  name: Yup.string()
    .min(2, 'Too Short!')
    .max(50, 'Too Long!')
    .required('Required'),
  subject: Yup.string()
    .min(5, 'Too Short!')
    .max(300, 'Too Long!')
    .required('Required'),
  message: Yup.string()
    .min(5, 'Too Short!')
    .max(300, 'Too Long!')
    .required('Required'),
  email: Yup.string()
    .email('Please enter a valid email')
    .required('Required'),
});

export default function ContactForm() {


  const openNotificationWithIcon = type => {
    if (type === 'success') {
      notification['success']({
        message: 'Your email has been sent!',
        description:
          ` Thank You! We will contact you shortly to answer your concern or question!.`,
      })
    } else {
      notification['error']({
        message: 'Sorry there was a problem on our end!',
        description: (<p>Feel Free to call us at <a href="tel:323-855-4242">323-855-4242</a>, or send us an email to <a href="mailto:info@schweiztercastle.com">info@schweiztercastle.com</a> </p>),
      })
    }
  };


  return (
    <div className="contact-item" style={{ textAlign: 'center' }}>
      <h2 style={{ fontFamily: "'IM Fell English SC', serif", fontSize: '32px', color: '#0D1B1E' }}>Send us an Email</h2>
      <hr style={{ border: '1px solid #0F7173' }} />
      <Formik
        initialValues={{
          name: '',
          subject: '',
          email: '',
          message: ''
        }}
        validationSchema={SignupSchema}
        onSubmit={values => {
          Axios.post('https://tranquil-peak-77628.herokuapp.com/send', values).then(res => openNotificationWithIcon('success')).catch(err => openNotificationWithIcon('error'))

        }}
      >
        {({ errors, touched }) => (
          <Form style={{ display: 'flex', flexDirection: 'column', justifyContent: 'center', alignItems: 'center', padding: '2%', fontFamily: "'Josefin Sans', sans-serif", color: '#0D1B1E' }}>
            <div style={{ width: '80%', margin: '5%' }}>
              <h2 style={{ textAlign: "left" }}>Name</h2>
              <Field style={{ width: '100%' }} name="name" placeholder="John Doe" />
              {errors.name && touched.name ? (
                <Text style={{ width: '100%', textAlign: "left" }} type="danger">{errors.name}</Text>
              ) : null}
            </div>

            <div style={{ width: '80%', margin: '5%' }}>
              <h2 style={{ textAlign: "left" }}>Email</h2>
              <Field style={{ width: '100%' }} name="email" type="email" placeholder="johndoe@example.com" />
              {errors.email && touched.email ? (
                <Text style={{ width: '100%', textAlign: "left" }} type="danger">{errors.email}</Text>
              ) : null}
            </div>

            <div style={{ width: '80%', margin: '5%' }}>
              <h2 style={{ textAlign: "left" }}>Subject</h2>
              <Field style={{ width: '100%' }} name="subject" placeholder="My Question or Comment" />
              {errors.subject && touched.subject ? (
                <Text style={{ width: '100%', textAlign: "left" }} type="danger">{errors.subject}</Text>
              ) : null}
            </div>


            <div style={{ width: '80%', margin: '5%' }}>

              <Field style={{ width: '100%' }} name="message" component="textarea" cols="30" rows="10" placeholder=" Comment or Question" />
              {errors.message && touched.message ? (
                <Text style={{ width: '100%', textAlign: "left" }} type="danger">{errors.message}</Text>
              ) : null}
            </div>


            <button style={{ background: 'none', border: '2px solid #0F7173 ', color: '#0F7173', cursor: 'pointer', padding: '10px', width: '20%', textAlign: 'center' }} type="submit" >Send</button>
          </Form>
        )}
      </Formik>
    </div>
  )
}


