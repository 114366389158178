import React from 'react'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faHiking, faBiking, faSkiing, faSkiingNordic, faWineGlassAlt, faFish, faWater, faBicycle, faDice, faCampground, faHelicopter, faBeer, faDotCircle, faPlus } from '@fortawesome/free-solid-svg-icons'

export default function IconGrid() {
    return (
        <div>
            <div className='activity-grid'>
                <a href="https://www.google.com/maps/search/hiking/@48.2837127,-116.8799624,9.52z"> <FontAwesomeIcon className='icon' icon={faHiking} /> <p>Hiking</p></a>
                <a href="https://www.google.com/maps/search/biking+trails/@48.3068786,-116.6475354,11z/data=!4m2!2m1!6e1"> <FontAwesomeIcon className='icon' icon={faBiking} /> <p>Biking</p></a>
                <a href="https://www.google.com/maps/search/winery/@48.3067085,-116.6475358,11z/data=!3m1!4b1"> <FontAwesomeIcon className='icon' icon={faWineGlassAlt} /> <p>Wineries</p></a>
                <a href="https://www.google.com/maps/search/brewery/@48.3065383,-116.6475362,11z/data=!3m1!4b1"> <FontAwesomeIcon className='icon' icon={faBeer} /> <p>Breweries</p></a>
                <a href="https://www.sandpoint.com/recreation/fishing.php"> <FontAwesomeIcon className='icon' icon={faFish} /><p>Fishing</p></a>
                <a href="https://www.google.com/maps/search/swimming/@48.2955733,-116.5348593,11z/data=!3m1!4b1!4m8!2m7!3m6!1sswimming!2sLake+Pend+Oreille,+Idaho,+USA!3s0x536181d77bd7eb85:0x620572512f339542!4m2!1d-116.4057217!2d48.140045"> <FontAwesomeIcon className='icon' icon={faWater} /><p>Lake Side Activities</p></a>
                <a href="https://www.google.com/maps/search/casinos/@48.1964124,-117.0347871,9z/data=!3m1!4b1"> <FontAwesomeIcon className='icon' icon={faDice} /> <p>Casinos</p></a>
                <a href="https://www.google.com/maps/search/camping/@48.296325,-116.6117467,12z/data=!4m6!2m5!5m3!5m2!4m1!1i2!6e1"> <FontAwesomeIcon className='icon' icon={faCampground} /><p>Camping</p></a>

                <a href="https://www.schweitzer.com/explore/trail-maps/"> <FontAwesomeIcon className='icon' icon={faSkiing} /> <p>Skiing</p></a>
                <a href="https://www.schweitzer.com/play/nordic-skiing/"> <FontAwesomeIcon className='icon' icon={faSkiingNordic} /><p>Nordic Skiing</p></a>
                <a href="https://www.schweitzer.com/play/snowbiking/"> <FontAwesomeIcon className='icon' icon={faBicycle} /> <p>Snow Biking</p></a>
                <a href="https://www.schweitzer.com/play/hermits-hollow/"> <FontAwesomeIcon className='icon' icon={faDotCircle} /><p>Snow Tubing</p></a>
                <a href="https://selkirkpowder.com/"> <FontAwesomeIcon className='icon' icon={faHelicopter} /><p>Cat/Heli Skiing and SnowMobiling</p></a>
                <a href="http://visitsandpoint.com/attraction-activities-category/"> <FontAwesomeIcon className='icon' icon={faPlus} /><p>Even More</p></a>

            </div>

        </div>
    )
}
