import React from 'react'
import { Link } from 'react-router-dom'
import Fade from 'react-reveal/Fade';
import { Icon } from "antd";

export default function MenuItems() {
    return (
        <Fade delay={1000} left>
            <div className="nav" >
                <Link className="nav" exact to="/" >
                    <div className="nav-item">
                        <h2 className='white'>Home</h2>
                        <hr className="white-bar" />
                    </div>

                </Link>
                <Link className="nav" exact to="/contact" >
                    <div className="nav-item">
                        <h2 className='white'>Contact</h2>
                        <hr className="white-bar" />
                    </div>
                </Link>

                <div className="nav-item">
                    <a href="https://www.facebook.com/schweitzercastle/"><Icon className='sm-icon white' type="facebook" /></a>
                    <a href="https://www.instagram.com/schweitzercastle/"><Icon className='sm-icon white' type="instagram" /></a>
                </div>
                <div className="nav white">
                    <h3 className='white'>Contact Info:</h3>
                    <a href="mailto:info@schweiztercastle.com"> <h3 className='white'>info@schweiztercastle.com</h3></a>
                    <a href="tel:323-855-4242"> <h3 className='white'>323-855-4242</h3></a>
                    <h3 className='white'>1018 Mogul Hill Rd, Sandpoint Idaho 83864.</h3>
                </div>


            </div>
        </Fade>
    )
}
