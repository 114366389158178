import React from 'react'
import { BrowserRouter, Switch, Route } from 'react-router-dom'
import App from './App'

import ContactPage from './components/ContactPage'
import Footer from './components/Footer'



const Router = () => {
    return (
        <BrowserRouter>
            <Switch>
                <Route exact path="/" component={App} />
                <Route path="/contact" component={ContactPage} />
            </Switch>
            <Footer />
        </BrowserRouter>
    )
}

export default Router 