import React from 'react'


export default function Footer() {
    return (
        <footer>
            <span> © Copyright 2019 - Schweitzer Castle - Chateau De Melusine </span> | <span>  All Rights Reserved  </span>  |  <span>  All Logos are the property of their respective owners</span> |
            <a href="mailto:info@schweiztercastle.com"> <span>email: info@schweiztercastle.com</span></a>
            |<a href="tel:323-855-4242"> <span>Tel: 323-855-4242</span></a> |  ] <span>Designed and created by: <a href="https://www.linkedin.com/in/eric-settels/">Eric Settels</a> </span>
        </footer>
    )
}
